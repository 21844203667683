<template>
    <div class="mainform">
    <!--  -->
    <div class="form">
        <el-form class="content bascform" ref="form" :model="form" label-width="130px">
            <div class="col8">
                <el-form-item label="索赔单号">
                <span class="onlyText">
                    {{form.companyCode}}
                </span>
                </el-form-item>
            </div>
            <div class="col8">
                <el-form-item label="申诉意见">
                    <el-input type="textarea" v-model="form.companyCode"></el-input>
                </el-form-item>
            </div>
        </el-form>
    </div>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'Complaintsopinion',
  props: [],
  data: function () {
    return {
      form: {
        companyCode: '123'
      }
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
</style>
